import { Close } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import "./SendMail.css";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { closeSendMessage } from "../features/mailSlice";
import { db } from '../Firebase';
import { addDoc, collection, serverTimestamp } from "firebase/firestore";

function SendMail() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();

  const onSubmit = (formData) => {
    console.log(formData);
        const email =
        {
        to: formData.to,
        subject: formData.subject,
        message: formData.message,
        timestamp: serverTimestamp() ,
        }
        addDoc(collection(db, "emails"), email)
        
        dispatch(closeSendMessage())
  };

//   function createPost() {
//     const post = {
//       title: "Finish Interview Section",
//       description: "Do Frontend Simplified",
//       uid: user.uid
//     };
//     addDoc(collection(db, "posts"), post)
//   }

  //   console.log(errors)
  return (
    <div className="sendMail">
      <div className="sendMail__header">
        <h3>New Message</h3>
        <Close
          onClick={() => dispatch(closeSendMessage())}
          className="sendMail__close"
        />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          placeholder="To"
          type="email"
          {...register("to", { required: true })}
        />
        {errors.to && (
          <p role="alert" className="sendMail__error">
            To is required!
          </p>
        )}
        <input
          placeholder="Subject"
          type="text"
          {...register("subject", { required: true })}
        />
        {errors.subject && (
          <p role="alert" className="sendMail__error">
            Subject is required!
          </p>
        )}
        <input
          placeholder="Message"
          type="textArea"
          className="sendMail__message"
          {...register("message", { required: true })}
        />
        {errors.message && (
          <p role="alert" className="sendMail__error">
            Message is required!
          </p>
        )}
        <div className="sendmail__options">
          <Button
            className="sendMail__send"
            variant="contained"
            color="primary"
            type="submit"
          >
            Send
          </Button>
        </div>
      </form>
    </div>
  );
}

export default SendMail;
