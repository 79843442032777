import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore"
import { getAuth } from 'firebase/auth'
import { GoogleAuthProvider } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyDkYykuWIAKdy11bU9lF32ptOPGcn8tgCY",
  authDomain: "clone-dca0c.firebaseapp.com",
  projectId: "clone-dca0c",
  storageBucket: "clone-dca0c.appspot.com",
  messagingSenderId: "401755183600",
  appId: "1:401755183600:web:f786e53bc84dea75222535",
  measurementId: "G-25HJBSV2P8",
};


const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
export const provider = new GoogleAuthProvider();
// export const provider = new firebase.auth.GoogleAuthProvider();

// export { auth, db, };
